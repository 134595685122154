import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { Suspense } from 'react';
import './App.css';
import { AuthenticatedUser } from './AuthenticatedUser';
import { createClient } from './lib/azure_ad';
import { LoginPage } from './views/LoginPage';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme/theme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Helmet } from 'react-helmet';

export function App() {
  const pca = createClient();
  console.log('##Login PCA in App=', pca);

  return (
    <Suspense fallback="loading">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={theme}>
          <div className="App">
            <Helmet titleTemplate="%s - Nexus" defaultTitle="Nexus">
              <meta name="description" content="Nexus applications" />
            </Helmet>
            <MsalProvider instance={pca}>
              <AuthenticatedTemplate>
                <AuthenticatedUser />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <LoginPage />
              </UnauthenticatedTemplate>
            </MsalProvider>
          </div>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Suspense>
  );
}
