import { Grid, Typography, Snackbar, Button } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelmetComponent } from '../components/HelmetComponent';
import {
  DangerousGoodsClassification,
  Maybe,
  useGetShipmentsByDateQuery,
  useShipmentLinkUnlinkSubSubscription,
} from '../generated/graphql';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../lib/date_time';
import { SelectDate } from '../components/SelectDate';
import { ImportShipmentFormRHF } from './ImportShipmentFormRHF';
import { Alert } from '@material-ui/lab';
import RefetchIcon from '@mui/icons-material/Refresh';

interface importShipmentProps {
  from: string;
  terminal: {
    id: number;
    name: string;
    country: string;
    locationId: number;
  };
}

export type ImportShipmentFormInput = {
  shipments: {
    id: number;
    name: string;
    shipmentDate?: Maybe<string>;
    shipmentStatus?: Maybe<string>;
    fromLocationId: number;
    toLocationId: number;
    createdBy: string;
    lastUpdatedBy: string;
    createdFrom?: Maybe<string>;
    updatedFrom?: Maybe<string>;
    loadingListItems: {
      id: number;
      locationId: number;
      checked: boolean;
      isDangerous: boolean;
      packages?: Maybe<number | string>;
      pallets?: Maybe<number | string>;
      palletSpace?: Maybe<number | string>;
      weight?: Maybe<number | string>;
      note: string;
      unNumber?: Maybe<number> | undefined;
      isLimitedQty: boolean;
      classification?: Maybe<DangerousGoodsClassification> | undefined;
      isImportedFromShipment?: boolean;
      routeDetail?: {
        id?: Maybe<number>;
        routeName?: Maybe<string>;
      };
      importedInRouteName?: Maybe<string>;
      customVOECId?: Maybe<string>;
      customInvoiceNo?: Maybe<string>;
      customMrnNo?: Maybe<string>;
      customWaybillNo?: Maybe<string>;
      customCmrNo?: Maybe<string>;
      customNote?: Maybe<string>;
      connectedNB?: Maybe<string>;
      isVoecCustomer?: boolean;
      isRemainingGoods?: boolean;
      customCleared?: boolean;
      customClearedForImport?: boolean;
      terminalShipmentNotArrived?: boolean;
      terminalCleared?: boolean;
      terminalNotCleared?: boolean;
      terminalDevPackages?: Maybe<number>;
      terminalDevPallets?: Maybe<number>;
      terminalDevPalletSpace?: Maybe<number>;
      terminalDevWeight?: Maybe<number>;
      terminalDevInfo?: Maybe<string>;
      files: {
        id: number;
        size: number;
        originalname: string;
        path: string;
        mimetype: string;
      }[];
    }[];
  }[];
};

export function ImportShipment(props: importShipmentProps) {
  const { terminal } = props;
  const { t } = useTranslation();
  const [date, setDate] = useState(format(new Date(), DATE_FORMAT));
  const onDateSelected = (date: string) => {
    setDate(date);
  };
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const {
    data: shipmentsData,
    loading,
    refetch: refetchShipments,
  } = useGetShipmentsByDateQuery({
    variables: {
      forDate: date,
      fromLocationId: terminal.locationId,
    },
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    refetchShipments({
      forDate: date,
      fromLocationId: terminal.locationId,
    });
  }, [date]);

  useShipmentLinkUnlinkSubSubscription({
    variables: {
      terminalId: terminal.locationId,
      transDate: date,
    },

    async onSubscriptionData({ subscriptionData }) {
      if (
        subscriptionData.data &&
        subscriptionData.data.shipmentLinkUnlinkSub &&
        subscriptionData.data.shipmentLinkUnlinkSub.terminalId ===
          terminal.locationId &&
        subscriptionData.data.shipmentLinkUnlinkSub.transDate === date &&
        subscriptionData.data.shipmentLinkUnlinkSub.routeId !== 0
      ) {
        setShowAlert(true);
        refetchShipments();
      }
    },
  });

  const allShipments: ImportShipmentFormInput = shipmentsData
    ? { shipments: shipmentsData.shipmentsByDate }
    : { shipments: [] };

  return (
    <>
      <Grid container spacing={1}>
        <HelmetComponent
          title={`${t('actions.import')} ${t('resource.shipment.lowercased')}`}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={showAlert}
          key={'alertSuccess'}
          autoHideDuration={3000}
          onClose={() => {
            setShowAlert(false);
          }}
        >
          <Alert
            onClose={() => {
              setShowAlert(false);
            }}
            severity="success"
          >
            {`${t('attributes.successMsg')}`}
          </Alert>
        </Snackbar>
        <Grid
          item
          container
          style={{ marginTop: 20 }}
          xs={12}
          direction={'row'}
        >
          <Grid item xs={2} justifyContent={'flex-start'}>
            <SelectDate
              label={t('attributes.forTransportDate')}
              currentDate={date}
              onDateSelected={onDateSelected}
            />
          </Grid>

          <Grid item xs={9} justifyContent={'center'}>
            <Typography
              style={{ color: '#68bc46', fontSize: 17, fontWeight: '600' }}
            >{`Current Terminal: ${
              terminal.name
            } | ${terminal.country.toUpperCase()}`}</Typography>
          </Grid>
          <Grid item xs={1} justifyContent={'flex-end'}>
            <Button
              title="Refresh"
              style={{ padding: 4 }}
              onClick={() => {
                refetchShipments();
              }}
              endIcon={<RefetchIcon />}
            >
              Refresh
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {loading ? (
        <div
          style={{
            height: '65vh',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          Loading...
        </div>
      ) : allShipments.shipments && allShipments.shipments.length > 0 ? (
        <Grid item container>
          <ImportShipmentFormRHF
            initialValues={allShipments}
            onSubmit={() => {
              console.info('@FORM');
            }}
            shipmentUpdated={() => {
              refetchShipments();
            }}
          />
        </Grid>
      ) : (
        <div
          style={{
            height: '65vh',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography>{`${t('validation.noShipmentFound')}`}</Typography>
        </div>
      )}
    </>
  );
}
