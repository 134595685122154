import { TFunction } from 'react-i18next';
import * as yup from 'yup';
import { CostAllocationItemType } from '../../generated/graphql';
import { CURRENCIES, FUEL } from '../constants';
import { loadingListItemYupValidation } from './loadingListItem';
import { TourTemplateFormInput } from '../../components/TourTemplateForm';

export function hasDuplicates(array: string[]) {
  const smallArr = array.map((n) => n.toLowerCase()).filter((r) => r !== '');
  return new Set(smallArr).size !== smallArr.length;
}

export const tourValidationSchema = (t: TFunction) =>
  yup.object({
    duplicateErr: yup
      .string()
      .required()
      .oneOf(['NO'], 'Duplicate Route names not allowed'),
    name: yup.string(),
    price: yup.number().positive(),
    currency: yup.string().oneOf(Object.keys(CURRENCIES)),
    capacity: yup.number().nullable().min(0),
    dispatcherId: yup.number().nullable(),
    subcontractorId: yup
      .number()
      .nullable()
      .required(
        t('validation.isRequired', { name: t('attributes.subcontractorId') }),
      ),
    note: yup.string(),
    routes: yup.array(
      yup.object().shape({
        startDate: yup
          .date()
          .required(
            t('validation.isRequired', { name: t('attributes.startDate') }),
          ),
        endDate: yup.date().nullable().min(yup.ref('startDate')),
        hasExceptionDates: yup.boolean(),
        startExceptionDate: yup
          .date()
          .when(['hasExceptionDates', 'routeDateType', 'exceptionDates'], {
            is: (
              hasExceptionDates: boolean,
              routeDateType: string,
              exceptionDates?: string[],
            ) =>
              hasExceptionDates &&
              routeDateType == 'dr' &&
              (exceptionDates == null || exceptionDates.length == 0),
            then: yup
              .date()
              .typeError('Start exception date is required')
              .min(yup.ref('startDate'))
              .required('Start exception date is required'),
            otherwise: yup.date().nullable().min(yup.ref('startDate')),
          }),
        endExceptionDate: yup
          .date()
          .when(['startExceptionDate', 'routeDateType'], {
            is: (startExceptionDate: string, routeDateType: string) =>
              routeDateType == 'dr' &&
              startExceptionDate != null &&
              startExceptionDate != '',
            then: yup
              .date()
              .typeError('End exception date is required')
              .min(yup.ref('startExceptionDate'))
              .required('End exception date is required'),
            otherwise: yup.date().nullable().min(yup.ref('startExceptionDate')),
          }),
        exceptionDates: yup.array().when('routeDateType', {
          is: (dateType: string) => dateType && dateType === 'cd',
          then: yup
            .array()
            .min(1, 'Choose at least one date')
            .required('At least one date is required'),
        }),
        days: yup.object().when('routeDateType', {
          is: (routeDateType: string) =>
            routeDateType && routeDateType === 'dr',
          then: yup
            .object()
            .shape({
              mon: yup.boolean(),
              tue: yup.boolean(),
              wed: yup.boolean(),
              thu: yup.boolean(),
              fri: yup.boolean(),
              sat: yup.boolean(),
              sun: yup.boolean(),
            })
            .test('testDays', 'One day needs to be set', (value, ctx) => {
              if (Object.values(value).every((x) => !x)) {
                return ctx.createError({
                  path: ctx.path,
                  message: `One day needs to be set`,
                });
              }

              return true;
            })
            .required(
              t('validation.isRequired', {
                name: t('attributes.days'),
              }),
            ),
        }),
        routeId: yup
          .string()
          .min(3)
          .required(
            t('validation.isRequired', { name: t('attributes.routeId') }),
          ),
        price: yup
          .number()
          .nullable()
          .test(
            'totalPrice',
            'Prices should sum up to price for tour',
            function (_value, ctx) {
              const contextGeneric = ctx as unknown as {
                from: { value: Record<string, unknown> }[];
              };
              const tour = contextGeneric.from[1]
                .value as TourTemplateFormInput;
              if (tour.price == null) {
                return true;
              }

              const tourPrice = tour.price;
              const routes = tour.routes;
              const sumOfRoutePrices = routes.reduce(
                (sum, r) => sum + (r.price ?? 0),
                0,
              );

              const diff =
                tourPrice - sumOfRoutePrices > 0
                  ? `${Math.abs(tourPrice - sumOfRoutePrices)} missing`
                  : `${Math.abs(tourPrice - sumOfRoutePrices)} too much`;
              if (tourPrice !== sumOfRoutePrices) {
                return ctx.createError({
                  path: ctx.path,
                  message: `Route prices should sum up to ${tourPrice}. Diff: ${diff}`,
                });
              }
              return true;
            },
          ),
        costAllocations: yup
          .array(
            yup
              .object({
                departmentId: yup.string(),
                type: yup.string().oneOf(Object.values(CostAllocationItemType)),
                includedKpi: yup.bool(),
                includedPrice: yup.bool(),
                cost: yup.number().test('cost', (value, ctx) => {
                  const contextGeneric = ctx as unknown as {
                    from: { value: Record<string, unknown> }[];
                  };
                  const tour = contextGeneric.from[1]
                    .value as TourTemplateFormInput;

                  if (tour.price == null || tour.price == 0) {
                    return true;
                  } else if (value === 0) {
                    return ctx.createError({
                      path: ctx.path,
                      message: `Can not be 0`,
                    });
                  }
                  return true;
                }),
                percentage: yup.number(),
                comment: yup.string(),
              })
              .omit(['percentage']),
          )
          .test('Values need to be same as route price', (value, ctx) => {
            const routePrice = ctx.parent.price;
            if (routePrice == null) {
              return true;
            }

            const totalCost =
              value
                ?.filter((x) => x.includedPrice === true)
                .reduce(
                  (sum: number, x: { cost: number | undefined }) =>
                    sum + (x.cost || 0),
                  0,
                ) || 0;

            const diff =
              routePrice - totalCost > 0
                ? `${Math.abs(routePrice - totalCost)} missing`
                : `${Math.abs(routePrice - totalCost)} too much`;
            if (routePrice !== totalCost) {
              return ctx.createError({
                path: ctx.path,
                message: `Cost allocations with cost allocations should sum up to ${routePrice}. Diff: ${diff}`,
              });
            }

            return true;
          }),
        files: yup.array(
          yup.object().shape({
            id: yup.string(),
            originalname: yup.string(),
          }),
        ),
        legs: yup
          .array(
            yup.object().shape({
              fuel: yup.string().oneOf(Object.keys(FUEL)),
              arrivalTime: yup
                .string()
                .nullable()
                .matches(
                  /^\d{2}:\d{2}(:\d{2})?$/,
                  t('validation.timeFormatError'),
                )
                .required(
                  t('validation.isRequired', {
                    name: t('attributes.arrivalTime'),
                  }),
                ),
              departureTime: yup
                .string()
                .nullable()
                .matches(
                  /^\d{2}:\d{2}(:\d{2})?$/,
                  t('validation.timeFormatError'),
                ),
              locationId: yup.number().required(
                t('validation.isRequired', {
                  name: t('attributes.location'),
                }),
              ),
              note: yup.string(),
              load: yup.boolean(),
              loadingListItems: loadingListItemYupValidation(t),
            }),
          )
          .required(),
      }),
    ),
  });
