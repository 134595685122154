import PageviewIcon from '@material-ui/icons/Pageview';
import { Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';

interface cardColumnsOptions {
  t: TFunction;
  url: string;
}

export const tourTemplateColumns = (
  options: cardColumnsOptions,
): GridColDef[] => {
  const { t, url } = options;
  return [
    {
      headerName: 'View Tour',
      field: 'viewTour',
      width: 75,
      type: 'action',
      renderCell: (params: GridCellParams) => {
        return (
          <Tooltip title={`${t('actions.view')}`}>
            <Button to={`${url}/${params.row.id}`} component={Link}>
              <PageviewIcon color="action" />
            </Button>
          </Tooltip>
        );
      },
    },
    {
      field: 'name',
      type: 'string',
      headerName: t(`attributes.nameWithResource`, {
        item: t('resource.tour.capitalized'),
      }),
      minWidth: 100,
    },
    {
      field: 'startDate',
      type: 'string',
      headerName: t(`attributes.startDate`),
      minWidth: 100,
      valueGetter({ row }) {
        return row.isNewVersion ? 'N/A' : row.startDate;
      },
    },
    {
      field: 'endDate',
      type: 'string',
      headerName: t(`attributes.endDate`),
      minWidth: 100,
      valueGetter({ row }) {
        return row.isNewVersion ? 'N/A' : row.endDate;
      },
    },
    {
      field: 'isActive',
      type: 'boolean',
      headerName: t(`attributes.active`),
      minWidth: 100,
      valueGetter({ row }) {
        return row.isNewVersion ? false : row.isActive;
      },
    },
    {
      field: 'subcontractorName',
      headerName: t('resource.subcontractor.capitalized'),
      minWidth: 100,
    },
    {
      field: 'capacity',
      type: 'string',
      headerName: t(`attributes.capacity`),
      minWidth: 100,
      valueGetter({ row }) {
        return row.isNewVersion
          ? 'N/A'
          : row.capacity != null
          ? row.capacity
          : '';
      },
    },
    {
      field: 'price',
      type: 'number',
      headerName: t(`attributes.price`),
      minWidth: 100,
      valueGetter({ value, row }) {
        const currency =
          typeof row.currency === 'string' ? row.currency.toUpperCase() : '';
        return `${value} ${currency}`;
      },
    },
    {
      field: 'responsiblePerson',
      type: 'string',
      headerName: t(`attributes.responsiblePerson`),
      minWidth: 100,
    },
    {
      field: 'groupingTag',
      type: 'string',
      headerName: t(`attributes.groupingTag`),
      minWidth: 100,
    },
    {
      field: 'note',
      type: 'string',
      headerName: t(`attributes.noteWithResource`, {
        resource: t('resource.tour.capitalized'),
      }),
      minWidth: 100,
      valueFormatter({ value }) {
        if (!value) {
          return '';
        }
        return (value as string).replaceAll('\n', ' ');
      },
    },
    {
      field: 'tourAgreementNo',
      type: 'string',
      headerName: t(`attributes.agreementNumber`),
      minWidth: 100,
    },
    {
      field: 'createdBy',
      type: 'string',
      headerName: t('attributes.createdBy'),
      minWidth: 100,
      valueGetter({ row }) {
        return row.createdBy;
      },
    },
    {
      field: 'lastUpdatedBy',
      type: 'string',
      headerName: t('attributes.lastUpdatedBy'),
      minWidth: 100,
      valueGetter({ row }) {
        return row.lastUpdatedBy;
      },
    },
    {
      field: 'lastUpdated',
      type: 'string',
      headerName: t('attributes.updatedAt'),
      minWidth: 100,
      valueGetter({ row }) {
        return row.updatedAt;
      },
    },
    {
      field: 'fuel',
      type: 'string',
      headerName: 'Fuel',
      minWidth: 100,
      valueGetter({ row }) {
        return row.fuel;
      },
    },
    {
      field: 'dispatcherName',
      type: 'string',
      headerName: 'Dispatcher',
      minWidth: 100,
    },
    {
      field: 'startExceptionDate',
      type: 'date',
      headerName: 'Exception date start',
      minWidth: 100,
      valueGetter({ row }) {
        return row.isNewVersion ? 'N/A' : row.startExceptionDate;
      },
    },
    {
      field: 'endExceptionDate',
      type: 'date',
      headerName: 'Exception date end',
      minWidth: 100,
      valueGetter({ row }) {
        return row.isNewVersion ? 'N/A' : row.endExceptionDate;
      },
    },
    {
      field: 'isNewVersion',
      type: 'boolean',
      headerName: 'NewVersion',
      valueGetter({ row }) {
        return row.isNewVersion;
      },
      minWidth: 80,
    },
  ];
};
