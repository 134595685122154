export function numberOrNull(input: unknown): number | null {
  return typeof input === 'number' ? input : null;
}
export function numberOrEmpty(input: unknown): number | string {
  return typeof input === 'number' ? input : '';
}
export function _toNumber(input: unknown): number {
  if (typeof input === 'number') return input;
  if (input === '0' || input === '') return 0;
  return parseInt(`${input}`);
}

export function _toNumberOrUndefined(input: unknown): number | undefined {
  if (input === undefined) return undefined;
  if (typeof input === 'number') return input;
  if (input === '' || input === '0') return undefined;
  return parseInt(`${input}`);
}
