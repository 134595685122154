import {
  Button,
  createStyles,
  List,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Grid, ListItem } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useTranslation } from 'react-i18next';
import { FuelType } from '../../../generated/graphql';
import { newLeg } from '../../../lib/formHelpers/leg';
import { LoadingListItemInterface } from '../../LoadingList';
import { LegFields } from '../LegFields';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxLabel: {
      fontSize: theme.typography.fontSize,
    },
    field: {
      width: '95%',
      fontSize: '10px',
      margin: 0,
    },
    iconButton: {
      padding: '2px',
    },
    loadingListButton: {
      padding: 0,
    },
    list: {
      padding: '0 !important',
    },
    transportationWrapper: {
      marginRight: 60,
    },
    fieldsRoot: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      flexWrap: 'wrap',

      '& > *': {
        display: 'flex',
        width: '50%',
        alignItems: 'center',
      },
    },
    fieldsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',

      '& > *': {
        marginLeft: theme.spacing(1),
      },
    },
  }),
);

interface LegsFieldsProps {
  readOnly: boolean;
  routeIndex: number;
  fieldsPath: string;
}

interface TourRouteLegInterface {
  id?: number;
  key: string;
  locationId?: number;
  position: number;
  load: boolean;
  unload: boolean;
  fuel: FuelType;
  arrivalTime?: Maybe<string>;
  departureTime?: Maybe<string>;
  note?: string;
  transportationDateOffset: number;
  daysDiff?: number;
  location?: { deletedBy?: string };
  loadingListItems: LoadingListItemInterface[];
  transportDay?: number;
}

export function TourRouteLegsFields({
  readOnly,
  fieldsPath,
  routeIndex,
}: LegsFieldsProps) {
  const { t } = useTranslation();
  const { getFieldProps } = useFormikContext();
  const classes = useStyles();
  const isCopyDriverPlateInfo = getFieldProps(
    `routes.${routeIndex}.isCopyDriverPlateInfo`,
  ).value;
  const legsFieldsProps = getFieldProps(`${fieldsPath}`);
  const legs: TourRouteLegInterface[] = legsFieldsProps.value;

  const driverName = getFieldProps(
    `routes.${routeIndex}.routeDriverName`,
  ).value;
  const driverPhoneNo = getFieldProps(
    `routes.${routeIndex}.routeDriverPhoneNumber`,
  ).value;
  const carRegNo = getFieldProps(
    `routes.${routeIndex}.routeCarRegNumber`,
  ).value;
  const trailerRegNo = getFieldProps(
    `routes.${routeIndex}.routeTrailerRegNumber`,
  ).value;

  return (
    <FieldArray
      name={fieldsPath}
      render={(arrayHelpers) => (
        <>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1" align="left">
              {t('resource.routeLeg.plural')}
            </Typography>
            {!readOnly && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  arrayHelpers.push(
                    newLeg(
                      '',
                      undefined,
                      isCopyDriverPlateInfo
                        ? {
                            routeDriverName: driverName,
                            routeDriverPhoneNumber: driverPhoneNo,
                            carRegistrationNumber: carRegNo,
                            trailerRegistrationNumber: trailerRegNo,
                          }
                        : undefined,
                      legs && legs.length > 0 ? legs.length : 0,
                    )[0],
                  );
                }}
              >
                {t('button.add', {
                  item: t('leg'),
                })}
              </Button>
            )}
          </Grid>
          {legs && legs?.length > 0 && (
            <List className={classes.list}>
              {legs.map((leg, legIndex) => (
                <>
                  <ListItem key={leg.key} className={classes.list}>
                    <LegFields
                      key={leg.key}
                      onRemove={(index) => arrayHelpers.remove(index)}
                      onMoveUp={(index) => arrayHelpers.swap(index, index - 1)}
                      onMoveDown={(index) =>
                        arrayHelpers.swap(index, index + 1)
                      }
                      leg={leg}
                      legsPath={fieldsPath}
                      index={legIndex}
                      legsLength={legs.length}
                      readOnly={readOnly}
                      trafficReadOnly={readOnly}
                      from={'TourRouteLeg'}
                      fromCreateRoute={'TourRouteLeg'}
                      isFromTerminal={false}
                      isCreateTerminalRoute={false}
                    />
                  </ListItem>
                </>
              ))}
            </List>
          )}
        </>
      )}
    />
  );
}
