import { Box, TextField, Typography } from '@material-ui/core';
import { GridApiRef, GridCellParams } from '@mui/x-data-grid-pro';

interface EditableCellProps {
  apiRef: GridApiRef;
  editable?: boolean;
}

export function CommonGridCell(props: EditableCellProps & GridCellParams) {
  const { value, id, field, apiRef, editable = true } = props;

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      {editable ? (
        <TextField
          value={value}
          style={{ backgroundColor: '#e6edfc' }}
          onClick={() => {
            apiRef.current.setCellMode(id, field, 'edit');
          }}
          fullWidth
          inputProps={{ min: 0, style: { textAlign: 'center' } }}
          InputProps={{ disableUnderline: true }}
        />
      ) : (
        <Typography>{value}</Typography>
      )}
    </Box>
  );
}
