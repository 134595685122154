import {
  AuthenticationResult,
  Configuration,
  EventMessage,
  InteractionRequiredAuthError,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { configuration } from '../configuration';
import { EventType } from '@azure/msal-browser';
import { CustomNavigationClient } from './CustomNavigation';
import { useHistory } from 'react-router-dom';

const LOGIN_REQUEST = {
  scopes: ['User.Read', 'openid'],
};

// MSAL configuration
const azureAdConfiguration: Configuration = {
  auth: {
    clientId: configuration.azure.clientId,
    redirectUri: configuration.azure.redirectUri,
    authority: configuration.azure.authority,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

export function createClient() {
  const pca = new PublicClientApplication(azureAdConfiguration);

  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  pca.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      pca.setActiveAccount(account);
    }
  });

  const accounts = pca.getAllAccounts();
  if (accounts.length > 0) {
    pca.setActiveAccount(accounts[0]);
  }
  return pca;
}

export function loginUser(instance: IPublicClientApplication) {
  console.log('##Login Now');
  instance
    .loginPopup(LOGIN_REQUEST)
    .then((res) => {
      console.log('##Login Success', res);
    })
    .catch((e) => {
      console.log('##Login Error', e);
      console.log('##Login ErrMessage', e.message);
    });
}

export async function getAccessToken(
  instance: IPublicClientApplication,
): Promise<string | undefined> {
  try {
    const { accessToken } = await instance.acquireTokenSilent({
      scopes: configuration.azure.scopes,
      authority: configuration.azure.authority,
    });
    return accessToken;
  } catch (err) {
    if (err instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      instance.acquireTokenRedirect(LOGIN_REQUEST);
    }
  }
}
