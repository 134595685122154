import { v4 as uuidv4 } from 'uuid';
import { LoadingListItemInterface } from '../../components/LoadingList';
import {
  FuelType,
  LocationEntity,
  Maybe,
  TourRouteLegLoadingListItemTemplate,
  TourRouteLegTemplate,
} from '../../generated/graphql';

export function newLeg(
  productionDate = '',
  copiedLegs?: Array<
    { __typename?: 'TourRouteLegTemplate' } & Pick<
      TourRouteLegTemplate,
      | 'id'
      | 'locationId'
      | 'carRegistrationNumber'
      | 'gateNumber'
      | 'trailerRegistrationNumber'
      | 'note'
      | 'arrivalTime'
      | 'departureTime'
      | 'position'
      | 'load'
      | 'unload'
      | 'transportationDateOffset'
      | 'routeDriverName'
      | 'routeDriverPhoneNumber'
      | 'fuel'
    > & {
        location: { __typename?: 'LocationEntity' } & Pick<
          LocationEntity,
          'name' | 'city' | 'country'
        >;
        loadingListItems: Array<
          { __typename?: 'TourRouteLegLoadingListItemTemplate' } & Pick<
            TourRouteLegLoadingListItemTemplate,
            | 'id'
            | 'locationId'
            | 'packages'
            | 'pallets'
            | 'palletSpace'
            | 'weight'
            | 'note'
            | 'checked'
            | 'isDangerous'
            | 'classification'
            | 'unNumber'
            | 'isLimitedQty'
            | 'lastUpdatedBy'
            | 'createdBy'
            | 'updatedAt'
          >
        >;
      }
  >,
  driverInfoToCopy?: {
    routeDriverName: string;
    routeDriverPhoneNumber: string;
    carRegistrationNumber: string;
    trailerRegistrationNumber: string;
  },
  legsLength = 0,
): [
  {
    id?: number;
    fuel: FuelType;
    key: string;
    gateNumber?: string;
    route?: string;
    routeDriverName?: string;
    routeDriverPhoneNumber?: string;
    carRegistrationNumber?: string;
    trailerRegistrationNumber?: string;
    load: boolean;
    unload: boolean;
    note?: string;
    arrivalTime?: Maybe<string>;
    departureTime?: Maybe<string>;
    position: number;
    locationId?: number;
    loadingListItems: LoadingListItemInterface[];
    productionDate: string;
    transportationDate: string;
    transportationDateOffset: number;
    transportDay?: number;
  },
] {
  if (copiedLegs?.length) {
    let resLegArray:
      | [
          {
            id?: number;
            key: string;
            fuel: FuelType;
            gateNumber?: string;
            routeDriverName?: string;
            routeDriverPhoneNumber?: string;
            carRegistrationNumber?: string;
            trailerRegistrationNumber?: string;
            load: boolean;
            unload: boolean;
            note?: string;
            arrivalTime?: Maybe<string>;
            departureTime?: Maybe<string>;
            position: number;
            locationId?: number;
            loadingListItems: LoadingListItemInterface[];
            productionDate: string;
            transportationDate: string;
            transportationDateOffset: number;
            transportDay?: number;
          },
        ]
      | undefined = undefined;
    copiedLegs.forEach((copiedLeg) => {
      const resLeg: {
        id?: number;
        key: string;
        fuel: FuelType;
        gateNumber?: string;
        routeDriverName?: string;
        routeDriverPhoneNumber?: string;
        carRegistrationNumber?: string;
        trailerRegistrationNumber?: string;
        load: boolean;
        unload: boolean;
        note?: string;
        arrivalTime?: Maybe<string>;
        departureTime?: Maybe<string>;
        position: number;
        locationId?: number;
        loadingListItems: LoadingListItemInterface[];
        productionDate: string;
        transportationDate: string;
        transportationDateOffset: number;
        transportDay?: number;
      } = {
        key: uuidv4(),
        arrivalTime: copiedLeg.arrivalTime ?? undefined,
        departureTime: copiedLeg.departureTime ?? undefined,
        locationId: copiedLeg.locationId ?? undefined,
        position: copiedLeg.position ?? 0,
        gateNumber: copiedLeg.gateNumber ?? '',
        routeDriverName: copiedLeg.routeDriverName ?? '',
        routeDriverPhoneNumber: copiedLeg.routeDriverPhoneNumber ?? '',
        carRegistrationNumber: copiedLeg.carRegistrationNumber ?? '',
        trailerRegistrationNumber: copiedLeg.trailerRegistrationNumber ?? '',
        fuel: copiedLeg.fuel,
        note: copiedLeg.note ?? undefined,
        load: copiedLeg.load ?? false,
        unload: copiedLeg.unload ?? false,
        loadingListItems: copiedLeg.loadingListItems ?? [],
        transportationDate: productionDate,
        productionDate,
        transportationDateOffset: copiedLeg.transportationDateOffset ?? 0,
        transportDay: 0,
      };
      if (!resLegArray) {
        resLegArray = [resLeg];
      } else {
        resLegArray.push(resLeg);
      }
    });
    if (resLegArray) {
      return resLegArray;
    }
  }
  return [
    {
      key: uuidv4(),
      arrivalTime: undefined,
      departureTime: undefined,
      locationId: undefined,
      position: legsLength,
      note: undefined,
      gateNumber: '',
      routeDriverName:
        driverInfoToCopy != null ? driverInfoToCopy.routeDriverName : '',
      routeDriverPhoneNumber:
        driverInfoToCopy != null ? driverInfoToCopy.routeDriverPhoneNumber : '',
      carRegistrationNumber:
        driverInfoToCopy != null ? driverInfoToCopy.carRegistrationNumber : '',
      trailerRegistrationNumber:
        driverInfoToCopy != null
          ? driverInfoToCopy.trailerRegistrationNumber
          : '',
      load: false,
      unload: false,
      loadingListItems: [],
      transportationDate: productionDate,
      productionDate,
      transportationDateOffset: 0,
      fuel: FuelType.Diesel,
      transportDay: 0,
    },
  ];
}

export function newLegAppend(
  transportationDate = '',
  legPosition = 0,
  driverInfoToCopy?: {
    routeDriverName: string;
    routeDriverPhoneNumber: string;
    carRegistrationNumber: string;
    trailerRegistrationNumber: string;
  },
): [
  {
    id?: number;
    key: string;
    gateNumber?: string;
    routeDriverName?: string;
    routeDriverPhoneNumber?: string;
    carRegistrationNumber?: string;
    trailerRegistrationNumber?: string;
    load: boolean;
    unload: boolean;
    note?: string;
    arrivalTime?: Maybe<string>;
    departureTime?: Maybe<string>;
    position: number;
    locationId?: number;
    loadingListItems: LoadingListItemInterface[];
    productionDate: string;
    transportationDate: string;
    transportationDateOffset: number;
    fuel: FuelType;
  },
] {
  return [
    {
      key: uuidv4(),
      arrivalTime: undefined,
      departureTime: undefined,
      locationId: undefined,
      position: legPosition,
      note: undefined,
      gateNumber: '',
      routeDriverName:
        driverInfoToCopy != null ? driverInfoToCopy.routeDriverName : '',
      routeDriverPhoneNumber:
        driverInfoToCopy != null ? driverInfoToCopy.routeDriverPhoneNumber : '',
      carRegistrationNumber:
        driverInfoToCopy != null ? driverInfoToCopy.carRegistrationNumber : '',
      trailerRegistrationNumber:
        driverInfoToCopy != null
          ? driverInfoToCopy.trailerRegistrationNumber
          : '',
      load: false,
      unload: false,
      loadingListItems: [],
      transportationDate: transportationDate,
      productionDate: transportationDate,
      transportationDateOffset: 0,
      fuel: FuelType.Diesel,
    },
  ];
}
